<template>
    <div>
        <p v-if="hintText">{{ hintText }}</p>
        <v-switch
            :label="answer ? $t('yes') : $t('no')"
            v-model="answer"
            @change="updateAnswer"
        ></v-switch>
    </div>
</template>

<script>
import FieldTypes from '../../../mixins/FieldTypes';

export default {
    name: 'TrueFalse',
    props: {
        hintText: {
            default: null
        },
        questionId: {}
    },
    data() {
        return {
            answer: false
        };
    },
    mixins: [FieldTypes],
    created() {
        if (this.surveyMode === 'start-survey') {
            this.updateAnswer();
        }
    }
};
</script>
